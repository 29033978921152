
import { PropType, defineComponent } from "vue";
import { debounce } from "lodash";
import { SurveyAnswerRowDto } from "@/dto";

export default defineComponent({
	props: {
		answerRow: {
			type: Object as PropType<SurveyAnswerRowDto>,
		},
	},

	data() {
		return {
			answer: this.answerRow?.answerText ? this.answerRow.answerText : "",
		};
	},

	created() {
		this.emitAnswer = debounce(this.emitAnswer, 200);
	},

	watch: {
		answer: {
			handler(newVal, oldVal) {
				if (newVal != oldVal) this.emitAnswer(newVal);
			},
			immediate: false,
		},
	},

	methods: {
		emitAnswer(answer: string) {
			this.$emit("save", answer);
		},
	},
});
