
import {
	SurveyActivationDto,
	SurveyAnswerRowDto,
	SurveyAnswerRowOptionDto,
	SurveyQuestionDto,
} from "@/dto";
import { defineComponent, PropType } from "vue";
import { debounce } from "lodash";

export default defineComponent({
	props: {
		questionData: {
			type: Object as PropType<SurveyQuestionDto>,
			required: true,
		},

		lang: {
			type: String as PropType<string>,
			required: true,
		},

		activations: {
			type: Array as PropType<SurveyActivationDto[]>,
			default: () => [],
		},

		answerRow: {
			type: Object as PropType<SurveyAnswerRowDto>,
		},
	},

	created() {
		this.emitAnswer = debounce(this.emitAnswer, 200);
	},

	mounted() {
		this.answerNumbers = this.answerRow?.multiSelectOptions
			? this.answerRow?.multiSelectOptions.map((x) => x.optionId)
			: ([] as number[]);
		this.answerDtos = this.answerRow?.multiSelectOptions
			? this.answerRow?.multiSelectOptions
			: ([] as SurveyAnswerRowOptionDto[]);

		this.answerNumbers.forEach((num) => {
			if (this.optionHasActivation(num)) this.updateAddedActivations(num);
		});
	},

	data() {
		return {
			answerNumbers: [] as number[],
			answerDtos: [] as SurveyAnswerRowOptionDto[],
			activatedActivations: [] as SurveyActivationDto[],
			userHasEdited: false,
		};
	},

	methods: {
		checkLimit(event: Event, id: number) {
			this.userHasEdited = true;
			let target = event.target as HTMLInputElement;

			if (!target.checked) {
				let removedDto = this.questionData.options!.find((x) => x.id == id);
				this.answerDtos = this.answerDtos.filter((x) => x.optionId != removedDto!.id!);

				if (this.optionHasActivation(id)) this.updateRemovedActivations(id);
			}

			if (target.checked && this.answerNumbers.length <= this.questionData.maxOptionPicks!) {
				let newDto = this.questionData.options!.find((x) => x.id == id);
				this.answerDtos.push({
					optionId: newDto!.id!,
					otherOptionText: "",
				});
				if (this.optionHasActivation(id)) {
					this.updateAddedActivations(id);
				}
			} else this.answerNumbers = this.answerNumbers.filter((x) => x != id);
		},

		optionHasActivation(optionId: number) {
			if (!optionId) return false;

			if (this.activations && this.activations.some((x) => x.optionId == optionId)) return true;

			return false;
		},

		updateAddedActivations(optionId: number) {
			let activated = this.activations.filter((x) => x.optionId == optionId);
			this.activatedActivations = this.activatedActivations.concat(activated);
		},

		updateRemovedActivations(optionId: number) {
			this.activatedActivations = this.activatedActivations.filter(
				(x) => x.optionId != optionId
			);
		},

		emitAnswer(answers: SurveyAnswerRowOptionDto[]) {
			if (this.userHasEdited) this.$emit("save", answers);
		},
	},

	watch: {
		activatedActivations(val) {
			this.$emit("activatedActivations", val);
		},

		answerDtos: {
			handler(newVal) {
				this.emitAnswer(newVal);
			},
			immediate: false,
			deep: true,
		},
	},
});
