
import { i18n } from "@/i18n";
import {
	SurveyAnswerRowDto,
	SurveyQuestionDto,
	SurveyQuestionOptionTranslationDto,
	SurveyQuestionScorableTranslationDto,
} from "@/dto";
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
	props: {
		answerRow: {
			type: Object as PropType<SurveyAnswerRowDto>,
			required: false,
		},

		question: {
			type: Object as PropType<SurveyQuestionDto>,
			required: true,
		},
	},
	setup(props) {
		const cPointsScaleSize = computed(() => {
			if (!props.question) return 0;

			if (props.question.pointsMax && props.question.pointsMin) {
				let size = props.question.pointsMax! - props.question.pointsMin! + 1;
				if (props.question.showNoAnswerOption) size++;
				return size <= 10 ? size : 10;
			}

			return 0;
		});

		const cPoints = computed(() => {
			let arr = [];
			if (props.question.scoringIsReversed) {
				for (let i = props.question.pointsMax!; i >= props.question.pointsMin!; i--) {
					arr.push(i);
				}
			} else {
				for (let i = props.question.pointsMin!; i <= props.question.pointsMax!; i++) {
					arr.push(i);
				}
			}

			return arr;
		});

		const cNoAnswerText = computed(() => {
			return props.question.translations.find((x) => x.languageCode == i18n.global.locale)
				?.noAnswerText;
		});

		const getSmileys = (amount: number) => {
			if (amount == 2) return [5, 1];
			if (amount == 3) return [5, 3, 1];
			if (amount == 4) return [5, 4, 2, 1];
			if (amount == 5) return [5, 4, 3, 2, 1];
			return [];
		};

		const isOptionType = (typeName: string) => {
			if (
				typeName == "singleRadio" ||
				typeName == "singleDropdown" ||
				typeName == "multiCheckbox" ||
				typeName == "multiLimited"
			)
				return true;
			return false;
		};

		const optionName = (translations: SurveyQuestionOptionTranslationDto[]) => {
			let resp = "";
			[i18n.global.locale, "fi", "sv", "en"].forEach((lang) => {
				if (!resp) {
					let translation = translations.find((x) => x.languageCode == lang);
					if (translation?.text) resp = translation.text;
				}
			});

			return resp;
		};

		const scorableText = (translations: SurveyQuestionScorableTranslationDto[]) => {
			let resp = "";
			[i18n.global.locale, "fi", "sv", "en"].forEach((lang) => {
				if (!resp) {
					let translation = translations.find((x) => x.languageCode == lang);
					if (translation?.text) resp = translation.text;
				}
			});

			return resp;
		};

		const isAnswered = (optionId: number) => {
			if (props.answerRow?.answerText && props.answerRow.answerText.length > 0) return true;

			if (props.answerRow?.optionId == optionId) return true;

			if (props.answerRow?.multiSelectOptions?.some((x) => x.optionId == optionId)) return true;

			return false;
		};

		const isScoreAnswered = (scorableId: number, points: number, noAnswer: boolean) => {
			if (
				props.answerRow?.scores?.some(
					(x) =>
						x.scorableId == scorableId &&
						(x.points == points || (x.pointsNoAnswer && noAnswer))
				)
			)
				return true;

			return false;
		};

		return {
			cPointsScaleSize,
			cPoints,
			cNoAnswerText,
			getSmileys,
			isOptionType,
			optionName,
			scorableText,
			isAnswered,
			isScoreAnswered,
		};
	},
});
