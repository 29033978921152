<template>
	<router-view />
</template>

<style lang="scss">
@import "~@hakosalo/hakolib/dist/hakoLib.css";

body {
	min-height: 100vh;
	background-color: #f9f9f9;
}
</style>
