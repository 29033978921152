
import { defineComponent, ref, onBeforeMount, computed } from "vue";
import {
	SurveyActivationDto,
	SurveyAnswerDto,
	SurveyAnswerRowDto,
	SurveyDto,
	SurveyPageDto,
	SurveyQuestionDto,
	SurveyQuestionTypeDto,
} from "@/dto";
import axios from "axios";
import SurveyHeader from "@/components/SurveyHeader.vue";
import { i18n } from "@/i18n";
import { useRoute, useRouter } from "vue-router";
import SingleDropdownComponent from "@/components/ComponentsByType/SingleDropdownComponent.vue";
import SingleRadioComponent from "@/components/ComponentsByType/SingleRadioComponent.vue";
import SmileyComponent from "@/components/ComponentsByType/SmileyComponent.vue";
import TextMultiComponent from "@/components/ComponentsByType/TextMultiComponent.vue";
import TextSingleComponent from "@/components/ComponentsByType/TextSingleComponent.vue";
import MultiCheckboxComponent from "@/components/ComponentsByType/MultiCheckboxComponent.vue";
import MultiLimitedComponent from "@/components/ComponentsByType/MultiLimitedComponent.vue";
import NpsComponent from "@/components/ComponentsByType/NpsComponent.vue";
import ScoreComponent from "@/components/ComponentsByType/ScoreComponent.vue";
import store from "@/store";
import { sortBy } from "lodash";

export default defineComponent({
	setup() {
		const route = useRoute();
		const router = useRouter();

		onBeforeMount(async () => {
			// await getFirstPageText();
			await getSurvey();
			setLanguage();
		});

		const survey = ref<SurveyDto | null>(null);
		// const firstPageTexts = ref<SurveyDeliveryTranslationDto[]>([]);
		const currentPage = ref<number>(1);
		const currentPageId = ref<number | null>(null);
		const activations = ref<SurveyActivationDto[]>([]);
		const slideDirClass = ref("next-page-fade");
		const activatedActivations = ref<SurveyActivationDto[]>([]);
		const loading = ref(true);
		const answerDto = ref<SurveyAnswerDto | null>(null);
		const langFromUrl = ref<"fi" | "sv" | "en" | undefined>(undefined);
		const invalidCode = ref(false);

		const cPageAmount = computed(() => {
			if (cPages.value.length > 1) return cPages.value.length;

			return 1;
		});

		const cPageIndicatorColor = computed(() => {
			if (survey.value && survey.value.pageIndicatorColor)
				return survey.value.pageIndicatorColor;

			return "#000000";
		});

		const cPages = computed((): SurveyPageDto[] => {
			if (!survey.value?.pages) return [];

			let activePages = survey.value.pages.filter((x) => checkIfPageActivated(x.id!));

			let sortedPages = sortBy(activePages, (x) => x.order);

			return sortedPages;
		});

		const cIsTestAnswer = computed((): boolean => {
			if (route.params["code"].length == 15) return true;

			return false;
		});

		const cIngress = computed(() => {
			let page = survey.value?.pages?.find((x) => x.id == currentPageId.value);
			if (!page) return "";

			return getTextInCurrLang(page.translations, "ingress");
		});

		const surveyName = (survey: SurveyDto | null) => {
			if (!survey) return "";

			let found = "" as string | null | undefined;
			[i18n.global.locale, "fi", "sv", "en"].forEach((lang) => {
				if (!found && survey?.translations.some((x) => x.languageCode == lang))
					found = survey!.translations.find((x) => x.languageCode == lang)?.name;
			});

			return found ? found : "";
		};

		// const getFirstPageText = async () => {
		// 	const code = route.params["code"];

		// 	try {
		// 		const response = await axios.get<SurveyDeliveryTranslationDto[]>(
		// 			`survey/FirstPageText/${code}`,
		// 			{
		// 				headers: {
		// 					organization: route.params["org"],
		// 				},
		// 			}
		// 		);
		// 		firstPageTexts.value = response.data;
		// 	} catch (ex: unknown) {
		// 		console.log(ex);
		// 		invalidCode.value = true;
		// 		loading.value = false;
		// 	}
		// };

		const getSurvey = async () => {
			const code = route.params["code"];

			try {
				const response = await axios.get<{ answer: SurveyAnswerDto; survey: SurveyDto }>(
					`survey/${code}`,
					{
						headers: {
							organization: route.params["org"],
						},
					}
				);
				survey.value = response.data.survey;
				answerDto.value = response.data.answer;
				await formatAnswerDto();

				if (survey.value.id) {
					currentPageId.value = survey.value?.pages ? survey.value.pages[0].id : null;
					await getActivations(survey.value.id);
				}
			} catch (ex: unknown) {
				console.log(ex);
				invalidCode.value = true;
				loading.value = false;
			}
		};

		const getActivations = async (surveyId: number) => {
			try {
				const response = await axios.get<SurveyActivationDto[]>(
					`survey/activations/${surveyId}`,
					{
						headers: {
							organization: route.params["org"],
						},
					}
				);
				activations.value = response.data;
			} catch (ex: unknown) {
				console.log("Problem loading activations");
			} finally {
				loading.value = false;
			}
		};

		const getTextInCurrLang = (translations: any[], key: string) => {
			if (!translations) return "";

			let found = translations.find((x) => x.languageCode == i18n.global.locale);

			if (found) return found[key] || "";

			return "";
		};

		const getComponentName = (questionTypeId: number): string => {
			let componentMap = new Map<number, string>([
				[1, "SingleRadioComponent"],
				[2, "MultiCheckboxComponent"],
				[3, "TextMultiComponent"],
				[4, "ScoreComponent"],
				[5, "SingleDropdownComponent"],
				[7, "TextSingleComponent"],
				[8, "SmileyComponent"],
				[9, "NpsComponent"],
				[10, "MultiLimitedComponent"],
			]);

			return componentMap.get(questionTypeId) || "";
		};

		const showPage = (page: SurveyPageDto): boolean => {
			if (page?.id && currentPageId.value == page.id && checkIfPageActivated(page.id))
				return true;
			return false;
		};

		const previousPage = (index: number) => {
			slideDirClass.value = "prev-page-fade";
			currentPageId.value = cPages.value[index - 1].id;
			currentPage.value--;
		};

		const nextPage = async (index: number) => {
			if (!validateAnswers()) return;

			slideDirClass.value = "next-page-fade";
			currentPageId.value = cPages.value[index + 1].id;
			currentPage.value++;
		};

		const finished = async () => {
			if (cIsTestAnswer.value) {
				if (!validateAnswers()) return;

				store.commit("setTestAnswer", answerDto.value);

				router.push({ name: "thankYou" });
				return;
			}

			if (!answerDto.value?.id) await createNewAnswer();

			try {
				const response = await axios.post<boolean>(
					`survey/FinishAnswer/${answerDto.value!.id}`,
					null,
					{
						headers: {
							organization: route.params["org"],
							code: answerDto.value?.uniqueCode,
						},
					}
				);
				if (response?.data) {
					router.push({
						name: "thankYou",
						params: { org: route.params["org"], code: route.params["code"] },
					});
				}
			} catch (ex: unknown) {
				console.log(ex);
			}
		};

		const updateActivatedActivations = (list: SurveyActivationDto[], questionId: number) => {
			activatedActivations.value = activatedActivations.value.filter(
				(x) => !(x.activatingQuestionId == questionId || x.option?.questionId == questionId)
			);
			activatedActivations.value = activatedActivations.value.concat(list);
		};

		const checkIfPageActivated = (pageId: number) => {
			// If requires activation but no activation yet activates it
			if (
				activations.value.some((x) => x.activatesPageId == pageId) &&
				!activatedActivations.value.some((x) => x.activatesPageId == pageId)
			)
				return false;

			return true;
		};

		const checkIfQuestionActivated = (questionId: number) => {
			// If requires activation but no activation yet activates it
			if (
				activations.value.some((x) => x.activatesQuestionId == questionId) &&
				!activatedActivations.value.some((x) => x.activatesQuestionId == questionId)
			)
				return false;

			return true;
		};

		const saveAnswerRow = async (question: SurveyQuestionDto, answer: any) => {
			if (!answerDto.value?.id && !cIsTestAnswer.value) await createNewAnswer();

			let deleteAnswerRow = false;

			let answerRow = answerDto.value!.answerRows!.find((x) => x.questionId == question.id)!;
			answerRow.answerId = answerDto.value!.id;

			if (
				question.type?.technicalName == "textSingle" ||
				question.type?.technicalName == "textMulti"
			) {
				if (answer.trim() == "") deleteAnswerRow = true;

				answerRow.answerText = answer;
			} else if (question.type?.technicalName == "smiley") {
				if (answer == null) deleteAnswerRow = true;

				answerRow.smileyValue = answer;
			} else if (question.type?.technicalName == "nps") {
				if (answer == null) deleteAnswerRow = true;

				answerRow.npsValue = answer;
			} else if (
				question.type?.technicalName == "singleRadio" ||
				question.type?.technicalName == "singleDropdown"
			) {
				if (answer == null) deleteAnswerRow = true;

				answerRow.optionId = answer;
			} else if (
				question.type?.technicalName == "multiLimited" ||
				question.type?.technicalName == "multiCheckbox"
			) {
				if (answer.length == 0) deleteAnswerRow = true;

				answerRow.multiSelectOptions = answer;
			} else if (question.type?.technicalName == "score") {
				if (answer.length == 0) deleteAnswerRow = true;

				answerRow.scores = answer;
			}

			if (deleteAnswerRow && !answerRow.commentText) {
				answerRow = {
					answerId: answerDto.value?.id,
					questionId: question.id,
				} as SurveyAnswerRowDto;
			} else {
				deleteAnswerRow = false;
			}

			if (!cIsTestAnswer.value) {
				try {
					const response = deleteAnswerRow
						? await axios.post<boolean>(`survey/DeleteQuestionAnswer`, answerRow, {
								headers: {
									organization: route.params["org"],
									code: answerDto.value?.uniqueCode,
								},
						  })
						: await axios.post<boolean>(`survey/SaveQuestionAnswer`, answerRow, {
								headers: {
									organization: route.params["org"],
									code: answerDto.value?.uniqueCode,
								},
						  });
					if (response?.data) console.log("Question answer saved");
				} catch (ex: unknown) {
					console.log(ex);
				}
			}
		};

		const createNewAnswer = async () => {
			try {
				const response = await axios.post<SurveyAnswerDto>(
					`survey/NewAnswer/${survey.value!.id}`,
					null,
					{
						headers: {
							organization: route.params["org"],
						},
					}
				);
				if (!answerDto.value) answerDto.value = response.data;
				else {
					answerDto.value.id = response.data.id;
					answerDto.value.uniqueCode = response.data.uniqueCode;
				}
				router.push({
					name: "survey",
					params: { org: route.params["org"], code: answerDto.value.uniqueCode },
				});
			} catch (ex: unknown) {
				console.log(ex);
			}
		};

		const formatAnswerDto = async () => {
			if (!answerDto.value)
				answerDto.value = {
					id: 0,
					surveyId: survey.value?.id,
					answerRows: [] as SurveyAnswerRowDto[],
				} as SurveyAnswerDto;

			survey.value?.pages?.forEach((page) => {
				page.questions?.forEach((question) => {
					if (!answerDto.value!.answerRows!.some((x) => x.questionId == question.id)) {
						answerDto.value!.answerRows!.push({
							questionId: question.id,
						} as SurveyAnswerRowDto);
					}
				});
			});
		};

		const saveComment = async (questionId: number) => {
			if (cIsTestAnswer.value) return;

			if (!answerDto.value?.id) await createNewAnswer();

			let answerRow = {
				answerId: answerDto.value?.id,
				questionId: questionId,
				commentText: answerDto.value!.answerRows!.find((x) => x.questionId == questionId)!
					.commentText,
			} as SurveyAnswerRowDto;

			try {
				const response = await axios.post<boolean>(`survey/SaveQuestionComment`, answerRow, {
					headers: {
						organization: route.params["org"],
						code: answerDto.value?.uniqueCode,
					},
				});
				if (response?.data) console.log("Question comment saved");
			} catch (ex: unknown) {
				console.log(ex);
			}
		};

		const validateAnswers = () => {
			let shownQuestions = cPages.value
				.find((x) => x.id == currentPageId.value)!
				.questions!.filter((y) => checkIfQuestionActivated(y.id!));

			let requiredQuestions = shownQuestions?.filter((x) => x.isRequired);

			if (requiredQuestions.length == 0) return true;

			let answerRows = answerDto.value!.answerRows?.filter((x) =>
				requiredQuestions.map((y) => y.id!).includes(x.questionId)
			);

			return questionsAreAnswered(answerRows!);
		};

		const questionsAreAnswered = (answerRows: SurveyAnswerRowDto[]) => {
			let valid = true;
			answerRows.forEach((row) => {
				if (valid) {
					let rowValid = false;
					if (row.answerText) rowValid = true;
					if (row.optionId) rowValid = true;
					if (row.smileyValue) rowValid = true;
					if (row.npsValue) rowValid = true;
					if (row.multiSelectOptions && row.multiSelectOptions?.length > 0) rowValid = true;
					if (row.scores && row.scores.some((x) => x.points || x.pointsNoAnswer))
						rowValid = true;

					valid = rowValid;

					if (!valid) {
						let el = document.getElementById("question" + row.questionId)!;

						el.scrollIntoView({ block: "center", behavior: "smooth" });

						setTimeout(() => el.classList.toggle("error"), 500);

						setTimeout(() => el.classList.toggle("error"), 1500);
					}
				}
			});
			return valid;
		};

		const setLanguage = () => {
			let lang = route.params["lang"] as "fi" | "sv" | "en" | undefined;
			if (lang && ["fi", "sv", "en"].includes(lang)) langFromUrl.value = lang;
			else {
				(["fi", "sv", "en"] as ("fi" | "sv" | "en")[]).forEach((x) => {
					if (!lang && survey.value?.languages?.map((y) => y.code).includes(x)) lang = x;
				});
				langFromUrl.value = lang;
			}
		};

		const isAnswerType = (type: SurveyQuestionTypeDto | undefined) => {
			if (!type) return false;

			return type.id > 0;
		};

		return {
			loading,
			survey,
			// firstPageTexts,
			currentPage,
			currentPageId,
			slideDirClass,
			activations,
			activatedActivations,
			answerDto,
			langFromUrl,
			invalidCode,
			cPageAmount,
			cPageIndicatorColor,
			cPages,
			cIsTestAnswer,
			cIngress,
			surveyName,
			getTextInCurrLang,
			getComponentName,
			showPage,
			previousPage,
			nextPage,
			finished,
			updateActivatedActivations,
			checkIfQuestionActivated,
			saveAnswerRow,
			saveComment,
			setLanguage,
			isAnswerType,
		};
	},

	components: {
		SurveyHeader,
		SingleRadioComponent,
		NpsComponent,
		MultiLimitedComponent,
		TextSingleComponent,
		MultiCheckboxComponent,
		TextMultiComponent,
		SmileyComponent,
		SingleDropdownComponent,
		ScoreComponent,
	},
});
