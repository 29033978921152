
import { SurveyActivationDto, SurveyAnswerRowDto } from "@/dto";
import { PropType, defineComponent } from "vue";
import { debounce } from "lodash";

export default defineComponent({
	props: {
		activations: {
			type: Array as PropType<SurveyActivationDto[]>,
			default: () => [],
		},

		answerRow: {
			type: Object as PropType<SurveyAnswerRowDto>,
		},
	},

	data() {
		return {
			selected: null as number | null,
			userHasAnswered: false,
		};
	},

	created() {
		this.emitAnswer = debounce(this.emitAnswer, 200);
	},

	mounted() {
		this.selected = this.answerRow?.npsValue ? this.answerRow.npsValue : null;

		if (this.selected) this.updateActivations(this.selected);
	},

	methods: {
		updateActivations(numericValue: number) {
			let activations = this.activations.filter((x) => x.numericValue == numericValue);

			this.$emit("activatedActivations", activations);
		},

		emitAnswer(answer: string) {
			if (this.userHasAnswered) this.$emit("save", answer);
		},
	},

	watch: {
		selected: {
			handler(newVal, oldVal) {
				if (newVal != oldVal) {
					this.updateActivations(newVal);

					this.emitAnswer(newVal);
				}
			},
		},
	},
});
