import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7f90aeb3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "type" }
const _hoisted_3 = { class: "question" }
const _hoisted_4 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HakoSpinner = _resolveComponent("HakoSpinner")!
  const _component_AnswerForSingleQuestion = _resolveComponent("AnswerForSingleQuestion")!
  const _component_HakoContentBlock = _resolveComponent("HakoContentBlock")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_HakoSpinner, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answer.survey?.pages, (page) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: page.id
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(page.questions, (question) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: question.id!
              }, [
                (_ctx.questionWasAnswered(question.id!))
                  ? (_openBlock(), _createBlock(_component_HakoContentBlock, {
                      key: 0,
                      "no-header": true
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.questionType(question.type!.translations)), 1),
                        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.questionTitle(question.translations)), 1),
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.questionDescription(question.translations)), 1),
                        _createVNode(_component_AnswerForSingleQuestion, {
                          answerRow: _ctx.answer.answerRows?.find((x) => x.questionId == question.id),
                          question: question
                        }, null, 8, ["answerRow", "question"])
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ], 64))
        }), 128))
      ]))
}