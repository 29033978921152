import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7c1c8868"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nps" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], (num) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: num }, [
          _withDirectives(_createElementVNode("div", {
            class: "nps-item nps-item-active",
            onClick: _cache[0] || (_cache[0] = ($event: any) => {
						{
							_ctx.selected = null;
							_ctx.userHasAnswered = true;
						}
					})
          }, _toDisplayString(num), 513), [
            [_vShow, num == _ctx.selected]
          ]),
          _withDirectives(_createElementVNode("div", {
            class: "nps-item",
            onClick: ($event: any) => {
						{
							_ctx.selected = num;
							_ctx.userHasAnswered = true;
						}
					}
          }, _toDisplayString(num), 9, _hoisted_2), [
            [_vShow, num != _ctx.selected]
          ])
        ], 64))
      }), 64))
    ])
  ]))
}