
import { SurveyActivationDto, SurveyAnswerRowDto, SurveyQuestionDto } from "@/dto";
import { defineComponent, PropType } from "vue";
import { debounce } from "lodash";

export default defineComponent({
	props: {
		questionData: {
			type: Object as PropType<SurveyQuestionDto>,
			required: true,
		},

		activations: {
			type: Array as PropType<SurveyActivationDto[]>,
			default: () => [],
		},

		answerRow: {
			type: Object as PropType<SurveyAnswerRowDto>,
		},
	},

	data() {
		return {
			selected: null as number | null,
			userHasAnswered: false,
		};
	},

	created() {
		this.emitAnswer = debounce(this.emitAnswer, 200);
	},

	mounted() {
		this.selected = this.answerRow?.smileyValue ? this.answerRow.smileyValue : null;

		if (this.selected) this.updateActivations(this.selected);
	},

	watch: {
		selected: {
			handler(newVal, oldVal) {
				if (newVal != oldVal) {
					this.updateActivations(newVal);

					this.emitAnswer(newVal);
				}
			},
		},
	},

	methods: {
		getSmileys(amount: number) {
			if (amount == 2) return [5, 1];
			if (amount == 3) return [5, 3, 1];
			if (amount == 4) return [5, 4, 2, 1];
			if (amount == 5) return [5, 4, 3, 2, 1];
			return [];
		},

		updateActivations(numericValue: number) {
			let activations = this.activations.filter((x) => x.numericValue == numericValue);

			this.$emit("activatedActivations", activations);
		},

		emitAnswer(answer: number) {
			if (this.userHasAnswered) this.$emit("save", answer);
		},
	},
});
