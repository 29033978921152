import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-31222c9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "single-select-radio ms-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HakoRadio = _resolveComponent("HakoRadio")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cOptions, (option) => {
      return (_openBlock(), _createBlock(_component_HakoRadio, {
        key: option.value,
        modelValue: _ctx.answer,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.answer) = $event)),
        value: option.value
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(option.text), 1)
        ]),
        _: 2
      }, 1032, ["modelValue", "value"]))
    }), 128))
  ]))
}