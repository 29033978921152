import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import { i18n, setLanguageAsync } from "./i18n";
import { hakoLibVuePlugin } from "@hakosalo/hakolib";

// Set default base url
axios.defaults.baseURL = process.env["VUE_APP_CRM2API"];

// Mount app
createApp(App).use(store).use(router).use(i18n).use(hakoLibVuePlugin).mount("#app");

setLanguageAsync("fi");
