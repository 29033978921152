
import { SurveyActivationDto, SurveyAnswerRowDto, SurveyQuestionDto } from "@/dto";
import { ISelectOption } from "@hakosalo/hakolib";
import { defineComponent, PropType } from "vue";
import { debounce } from "lodash";

export default defineComponent({
	props: {
		questionData: {
			type: Object as PropType<SurveyQuestionDto>,
			required: true,
		},

		lang: {
			type: String as PropType<string>,
			required: true,
		},

		activations: {
			type: Array as PropType<SurveyActivationDto[]>,
			default: () => [] as SurveyActivationDto[],
		},

		answerRow: {
			type: Object as PropType<SurveyAnswerRowDto>,
		},
	},

	data() {
		return {
			answer: null as number | null,
			allowSaving: false,
		};
	},

	created() {
		this.emitAnswer = debounce(this.emitAnswer, 200);
	},

	mounted() {
		this.answer = this.answerRow?.optionId ? this.answerRow.optionId : null;
		setTimeout(() => (this.allowSaving = true), 1100);

		if (this.answer) this.updateActivations(this.answer);
	},

	computed: {
		cOptions() {
			let options = [] as ISelectOption<number | null>[];

			this.questionData.options?.forEach((x) => {
				options.push({
					value: x.id!,
					text: x.translations!.find((y) => y.languageCode == this.lang)!.text,
				});
			});

			return options;
		},
	},

	watch: {
		answer: {
			handler(newVal, oldVal) {
				if (newVal != oldVal) {
					this.updateActivations(newVal);

					this.emitAnswer(newVal);
				}
			},
			immediate: false,
		},
	},

	methods: {
		updateActivations(optionId: number) {
			let activations = this.activations.filter((x) => x.optionId == optionId);

			this.$emit("activatedActivations", activations);
		},

		emitAnswer(answer: number) {
			if (this.allowSaving) this.$emit("save", answer);
		},
	},
});
