import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9afebab8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "single-select" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HakoMultiselect = _resolveComponent("HakoMultiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HakoMultiselect, {
      modelValue: _ctx.answer,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.answer) = $event)),
      options: _ctx.cOptions,
      placeholder: _ctx.$t('select'),
      useAvatar: false
    }, null, 8, ["modelValue", "options", "placeholder"])
  ]))
}