import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2e74c330"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 1,
  class: "thank-you"
}
const _hoisted_3 = { class: "title" }
const _hoisted_4 = {
  key: 0,
  class: "mt-3 ms-3"
}
const _hoisted_5 = {
  key: 1,
  class: "mt-5 ms-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HakoSpinner = _resolveComponent("HakoSpinner")!
  const _component_HakoButton = _resolveComponent("HakoButton")!
  const _component_SurveyAnswer = _resolveComponent("SurveyAnswer")!
  const _component_HakoModal = _resolveComponent("HakoModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_HakoSpinner, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.thankYouTitle ? _ctx.thankYouTitle : _ctx.$t("thankYouForAnswering") + "!"), 1),
          (_ctx.thankYouText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.thankYouText), 1))
            : _createCommentVNode("", true),
          (_ctx.showAnswers)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", null, _toDisplayString(_ctx.$t("answerSummary")), 1),
                _createVNode(_component_HakoButton, {
                  class: "mt-3",
                  theme: "primary",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAnswersModal = true))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("showAnswers")), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_HakoModal, {
            modelValue: _ctx.showAnswersModal,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showAnswersModal) = $event)),
            title: _ctx.$t('answerSummary'),
            noCloseButton: ""
          }, {
            "modal-buttons": _withCtx(() => [
              _createVNode(_component_HakoButton, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAnswersModal = false)),
                theme: "secondary"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("cancel")), 1)
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_SurveyAnswer, {
                onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showAnswersModal = false)),
                code: (_ctx.$route.params['code'] as string),
                org: (_ctx.$route.params['org'] as string)
              }, null, 8, ["code", "org"])
            ]),
            _: 1
          }, 8, ["modelValue", "title"])
        ]))
  ]))
}