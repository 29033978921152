import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-652abe8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "active ms-2" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "ms-2" }
const _hoisted_4 = ["src", "alt", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSmileys(_ctx.questionData.smileyAmount!), (smiley) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: smiley }, [
        _withDirectives(_createElementVNode("span", _hoisted_1, [
          _createElementVNode("img", {
            src: '/assets/survey/' + smiley + '.png',
            alt: smiley + '',
            class: "smiley",
            onClick: _cache[0] || (_cache[0] = ($event: any) => {
						{
							_ctx.selected = null;
							_ctx.userHasAnswered = true;
						}
					})
          }, null, 8, _hoisted_2)
        ], 512), [
          [_vShow, _ctx.selected == smiley]
        ]),
        _withDirectives(_createElementVNode("span", _hoisted_3, [
          _createElementVNode("img", {
            src: '/assets/survey/' + smiley + '.png',
            alt: smiley + '',
            class: "smiley",
            onClick: ($event: any) => {
						{
							_ctx.selected = smiley;
							_ctx.userHasAnswered = true;
						}
					}
          }, null, 8, _hoisted_4)
        ], 512), [
          [_vShow, _ctx.selected != smiley]
        ])
      ], 64))
    }), 128))
  ]))
}