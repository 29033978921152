import { SurveyAnswerDto } from "@/dto";
import { createStore } from "vuex";

export default createStore({
	state: () => ({
		testAnswer: null as SurveyAnswerDto | null,
	}),
	getters: {
		getTestAnswer(state) {
			return state.testAnswer;
		},
	},
	mutations: {
		setTestAnswer(state, answer: SurveyAnswerDto) {
			state.testAnswer = answer;
		},
	},
	actions: {},
	modules: {},
});
