import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import SurveyView from "../views/SurveyView.vue";
import ThankYouView from "@/views/ThankYouView.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/:org/:code/:lang?",
		name: "survey",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		//  component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
		component: SurveyView,
	},
	{
		path: "/thankYou/:org/:code/",
		name: "thankYou",
		component: ThankYouView,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
